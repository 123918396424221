import React from 'react';
import { usePage } from '@inertiajs/inertia-react';
import img from '../../../../storage/app/public/about.webp';
import ikona from '../../../../storage/app/public/logo-ikona.png';

const AboutSection = ({ about }) => {

    return (
        <section>
            <div className="mx-auto container px-4 py-4 flex align-center mt-10">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-5">
                    <div className="md:col-span-2">
                        <img
                            alt=""
                            src={img}
                            className="h-40 w-full object-cover sm:h-[556px] rounded-xl"
                        />
                    </div>
                    <div className="bg-white p-8 md:p-14 lg:py-20 rounded-xl md:col-span-3">
                        <div className="text-center md:text-left">
                            <h2 className="text-2xl font-bold text-[#26303D] md:text-4xl justify-center md:justify-start mb-12 flex items-center gap-4">
                                <img src={ikona} alt="ikona" className='h-[34px]'/>{about.title}
                            </h2>

                            <p className="text-[#26303D]/80 sm:mt-4 sm:block leading-6">
                                <span className="block mb-6">
                                    {about.description}
                                </span>
                            </p>

                            <div className="mt-4 md:mt-10">
                                <a
                                    href={about.link}
                                    className="inline-block md:float-right bg-[#BE1622] rounded-3xl px-12 py-3 text-sm font-medium text-white transition hover:bg-[#BE1622]/80 hover:text-white"
                                >
                                    Zobraziť viac
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
